import React, { FC } from "react";
import styled from "styled-components";
import Content from "components/front/corporate/atoms/Content";
import Headline from "components/front/corporate/atoms/Headline";

export type Props = {}
const MissionSection: FC<Props> = ({ ...props }) => {
  return (
    <section {...props}>
      <Headline>ミッション</Headline>
      <Content>
        <BigText>
          好きなアニメを直接応援する文化の創造
          <br />
          <TextBlock>日本アニメが持続的に</TextBlock>
          <TextBlock>成長できる環境を作り出す</TextBlock>
        </BigText>
        <div>
          <Text>
            ファンが作品に還元するための主たる方法は「アニメの関連グッズ」を購入することです。
            <br />
            ですが「アニメの関連グッズ」にまつわるアンケートを取ったところ、 80%以上がグッズに関心があるのに対して、
            満足しているのは50％以下であるという結果になりました。
          </Text>
          <Text>
            ファンは“もっと作品を応援したい“という気持ちがあるにも関わらず その気持ちを還元できずにいる状況があります。
            <br />
            全てのファンに満足していただく体験を提供するのは難しいかもしれません。
            しかしファンのニーズをより理解し、開拓していくことで満足度を向上させ、 アニメ業界全体の収益をアップさせることは可能です。
          </Text>
          <Text>
            アニメファンドは「欲しい人」に「欲しい体験」を「欲しい価格」で提供するための
            アニメ特化型クラウドファンディングのサービスをリリースし、 アニメとファンの関係値を高めていきます。
          </Text>
        </div>
      </Content>
    </section>
  );
};
export default MissionSection;

const Text = styled.p`
  
  @media screen and (max-width: 960px) {
    margin: 10px 0;
    font-size: 14px;
    line-height: 1.8em;
  }
  @media screen and (min-width: 961px) {
    margin: 20px auto;
    font-size: 16px;
    line-height: 2.28em;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const BigText = styled(Text)`
  text-align: center;
  line-height: 1.62em;
  margin: 50px 0;
  @media screen and (max-width: 960px) {
    font-size: 18px;
  }
  @media screen and (min-width: 961px) {
    margin: 70px 0;
    font-size: 24px;
  }
`;

const TextBlock = styled.span`
  display: inline-block;
`;
